<template>
	<div class="bg-gray-50 pb-5 pt-6 px-4 md:px-6">
		<div class="max-w-7xl mx-auto">
			<div class="flex justify-between items-center">
				<div>
					<img :src="require('@/assets/logo.svg')" alt="Los Metro" class="w-36">
				</div>

				<a class="text-xs font-medium tracking-wider text-gray-800 pt-3 pb-2 px-4 rounded-md hover:bg-gray-100" href="https://losmetro.mx">
					<ChevronLeftIcon class="inline-block w-5 h-5 mb-0.5 mr-1" />
					REGRESAR <span class="hidden md:inline-block">A LOS METRO</span>
				</a>
			</div>
		</div>
  	</div>

  	<router-view/>
</template>

<script>
import "tailwindcss/tailwind.css"
import { ChevronLeftIcon } from '@heroicons/vue/solid'

export default {
	components: {
		ChevronLeftIcon
	}
}
</script>
